import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { Helmet } from "react-helmet";
import classNames from "classnames";

import { QuantityIcon } from "components/QuantityIcon";
import { PageHeader } from "components/PageHeader";
import { useCart } from "context/CartContext";
import { priceFormat } from "utils/format";
import { Checkbox } from "components/Checkbox";
import { Counter } from "components/Counter";
import { cartRemove, cartUpdate } from "services/cartApi";
import { Button } from "components/Button";
import { ReactComponent as TrashIcon } from "assets/img/svg/trash.svg";
import { useModal } from "hooks/useModal";
import { Spinner } from "components/Spinner";
import { Body } from "layout/Body";
import { MainBlock } from "layout/MainBlock";
import { RightSidebar } from "layout/RightSidebar";
import { BreadCrumbs } from "layout/BreadCrumbs";
import { useBreakpoint } from "hooks/useBreakpoint";
import { ButtonSmall } from "components/ButtonSmall";
import { count } from "utils/cart";

import styles from "./cart.module.scss";
import { RemoveConfirmContentModal } from "./RemoveConfirmContentModal";
import { OrderForm } from "./OrderForm";

export type TProvider = {
  id: string;
  name: string;
  probability: number;
  deliveryTime: string;
};

export type TCart = {
  index: string;
  id: string;
  article: string;
  brand: string;
  name: string;
  count: number;
  price: number;
  summ: number;
  exist: string;
  deliveryTime: string;
  provider: TProvider;
  priceListId: string;
  sklad: boolean;
  errors: string[];
};

export const CartPage: FC = () => {
  const { breakpoint, isMobile, isTablet, isDesktop } = useBreakpoint();
  const { cart, setCart, cartLoading, setCartLoading } = useCart();
  const [selected, setSelected] = useState<string[]>([]);
  const [cartSumm, setCartSumm] = useState(0);
  const [cartQuantity, setCartQuantity] = useState(0);
  const [, setShownRemoveConfirm, RemoveConfirmModal] = useModal();
  const [selectAll, setSelectAll] = useState<boolean>(false);

  useEffect(() => {
    const footer = document.getElementById("footer");

    if (!footer) return;

    if (cart.length > 0 && !isDesktop) {
      footer.style.paddingBottom = "160px";
      return () => {
        footer.style.removeProperty("padding-bottom");
      };
    }
  }, [breakpoint, cart]);

  const selectHandler = (id: string, isSelect: boolean) => {
    if (id === "all") {
      if (isSelect === true) {
        setSelected(cart.map((item: TCart) => item.id));
      } else {
        setSelected([]);
      }

      setSelectAll(isSelect);
      return;
    }

    setSelectAll(false);
    setSelected((prev: string[]) => {
      if (!isSelect) {
        prev.splice(
          prev.findIndex((val) => val === id),
          1
        );
        return [...prev];
      }
      return [...prev, id];
    });
  };

  useEffect(() => {
    if (selected.length > 0) {
      const summ = cart.reduce((acc: number, item: TCart) => {
        if (selected.find((id: string) => item.id === id)) {
          return acc + item.count * item.price;
        }
        return acc;
      }, 0);
      setCartSumm(summ);
    } else {
      const summ = cart.reduce((acc: number, item: TCart) => {
        return acc + item.count * item.price;
      }, 0);
      setCartSumm(summ);
    }
    setCartQuantity(selected.length > 0 ? selected.length : cart.length);
  }, [selected, cart]);

  const updateCart = (id: string, count: number) => {
    cartUpdate({ id, count })
      .then((res) => {
        setCart(res.cart);
      })
      .finally(() => {
        setCartLoading(false);
      });
  };

  const removeHandler = (id?: string) => {
    setShownRemoveConfirm(true);
    if (id) {
      setSelected([id]);
    }
  };

  const onConfirmRemove = () => {
    setCartLoading(true);
    setShownRemoveConfirm(false);
    cartRemove({ ids: selected })
      .then((res) => {
        setCart(res.cart);
        setSelected([]);
        toast.success("Товары успешно удалены");
      })
      .catch((e) => toast.error(e.response.data.message))
      .finally(() => {
        setCartLoading(false);
      });
  };

  const Product: FC<{ product: TCart }> = ({ product }) => {
    if (isDesktop) {
      return (
        <div className={classNames(
          styles.productItem,
          {
            [styles.error]: product.errors?.length > 0
          }
        )} key={product.id}>
          <div className={styles.cell}>{product.brand}</div>
          <div className={styles.cell}>{product.article}</div>
          <div className={styles.cell}>{product.name}</div>
          <div className={styles.cell}>{product.deliveryTime}</div>
          <div className={styles.cell}>{product.exist} шт.</div>
          <div className={styles.cell}>{priceFormat(product.price)} р.</div>
          <div className={styles.cell}>
            {product.errors?.length > 0 &&
              <div className={styles.errorBlock}>{product.errors.map(error => <p>{error}</p>)}</div>
            }
            <Counter
              max={product.exist}
              quantity={product.count}
              setQuantity={(value: number) => updateCart(product.id, value)}
              breakpoint={breakpoint}
            />
          </div>
          <div className={styles.cell}>{priceFormat(product.summ)} р.</div>
          <div className={styles.cell}>
            <button
              className={styles.remove}
              onClick={() => removeHandler(product.id)}
            >
              <TrashIcon />
            </button>
          </div>
          <div className={styles.cell}>
            <Checkbox
              id={product.id}
              checked={selected.indexOf(product.id) > -1}
              onChange={(e) => selectHandler(product.id, e.target.checked)}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className={classNames(
          styles.productItem,
          {
            [styles.error]: product.errors?.length > 0
          }
        )} key={product.id}>
          <div className={styles.productInfo}>
            <div>
              № <span className={styles.index}> {product.index}</span>
              <span className={styles.brand}>{product.brand}</span>
              <span className={styles.article}>{product.article}</span>
            </div>
            <div className={styles.name}>{product.name}</div>
          </div>
          {isTablet ?
            <>
              <div className={styles.counterBlock}>
                <div>
                  <Counter
                    max={product.exist}
                    quantity={product.count}
                    setQuantity={(value: number) => updateCart(product.id, value)}
                    breakpoint={breakpoint}
                  />
                  <div className={styles.priceBlock}>
                    <div>
                      <div className={styles.price}>
                        {priceFormat(product.price)} р.
                      </div>
                      <div className={styles.summ}>
                        {priceFormat(product.summ)} р.
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <QuantityIcon
                    exist={product.exist}
                    className={styles.quantityBlock}
                  >
                    {product.sklad ? "На складе" : product?.provider?.deliveryTime}
                  </QuantityIcon>
                </div>
              </div>
              <div className={styles.removeBlock}>

                <ButtonSmall
                  className={styles.remove}
                  onClick={() => removeHandler(product.id)}
                  breakpoint={breakpoint}
                >
                  <TrashIcon />
                </ButtonSmall>
                <Checkbox
                  id={product.id}
                  checked={selected.indexOf(product.id) > -1}
                  onChange={(e) => selectHandler(product.id, e.target.checked)}
                />
              </div>
            </>
            :
            <>
              <div>
                <div className={styles.counterBlock}>
                  <Counter
                    max={product.exist}
                    quantity={product.count}
                    setQuantity={(value: number) => updateCart(product.id, value)}
                    breakpoint={breakpoint}
                  />
                  <ButtonSmall
                    className={styles.remove}
                    onClick={() => removeHandler(product.id)}
                    breakpoint={breakpoint}
                  >
                    <TrashIcon />
                  </ButtonSmall>
                </div>
                <div className={styles.priceBlock}>
                  <div>
                    <div className={styles.price}>
                      {priceFormat(product.price)} р.
                    </div>
                    <div className={styles.summ}>
                      {priceFormat(product.summ)} р.
                    </div>
                  </div>
                  <div>
                    <Checkbox
                      id={product.id}
                      checked={selected.indexOf(product.id) > -1}
                      onChange={(e) => selectHandler(product.id, e.target.checked)}
                    />
                  </div>
                </div>
                <QuantityIcon
                  exist={product.exist}
                  className={styles.quantityBlock}
                >
                  {product.sklad ? "На складе" : product?.provider?.deliveryTime}
                </QuantityIcon>
              </div>
            </>
          }
        </div>
      );
    }
  };

  return (
    <>
      <BreadCrumbs data={[{ title: "Корзина" }]} />
      <Helmet>
        <title>Корзина</title>
      </Helmet>
      <div className={classNames(styles.cart, styles[breakpoint])}>
        {cartLoading && <Spinner />}

        <Body>
          <PageHeader title="Корзина" className={styles.cartHeader}>
            <div className={styles.countProducts}>Товаров: {count(cart)}</div>
            {selected.length > 0 && (
              <Button
                className={styles.removeBulk}
                onClick={() => removeHandler()}
                breakpoint={breakpoint}
              >
                <TrashIcon />
                Удалить ({selected.length} шт.)
              </Button>
            )}
          </PageHeader>
          <MainBlock>
            {cart.length > 0 ? (
              <>
                {isDesktop && (
                  <div className={styles.cartListBlock}>
                    <div className={styles.tableHeader}>
                      <div className={styles.cell}>Бренд</div>
                      <div className={styles.cell}>Артикул</div>
                      <div className={styles.cell}>Наименование</div>
                      <div className={styles.cell}>Срок поставки</div>
                      <div className={styles.cell}>Наличие</div>
                      <div className={styles.cell}>Цена</div>
                      <div className={styles.cell}>Кол-во</div>
                      <div className={styles.cell}>Сумма</div>
                      <div className={styles.cell}></div>
                      <div className={styles.cell}>
                        <Checkbox
                          id="all"
                          checked={selectAll}
                          onChange={(e) =>
                            selectHandler("all", e.target.checked)
                          }
                        />
                      </div>
                    </div>

                    <PerfectScrollbar className={styles.tableBody}>
                      <div>
                        {cart.map((product: TCart) => (
                          <Product product={product} key={product.id} />
                        ))}
                      </div>
                    </PerfectScrollbar>
                  </div>
                )}
                {!isDesktop && (
                  <div>
                    {cart.map((product: TCart) => (
                      <Product product={product} key={product.id} />
                    ))}
                  </div>
                )}
              </>
            ) : (
              <div className={styles.emptyCart}>
                <div className={styles.emptyCartImg} />
                <div className={styles.emptyCartTitle}>Корзина пока пуста</div>
                <div className={styles.emptyCartSubTitle}>
                  Тут будут отображаться ваши товары
                </div>
              </div>
            )}
            <OrderForm
              setSelected={setSelected}
              selected={selected}
              cartSumm={cartSumm}
              cartQuantity={cartQuantity}
            />
          </MainBlock>
        </Body>
        {!isMobile && <RightSidebar />}
        <RemoveConfirmModal>
          <RemoveConfirmContentModal breakpoint={breakpoint} onConfirm={onConfirmRemove} />
        </RemoveConfirmModal>
      </div>
    </>
  );
};
